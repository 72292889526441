<template>
  <div>
    <!--  banner 轮播部分-->
    <page-banner pageName="home" :pageNumber="1" />
    <header_nav />
    <div class="banner_long">
      <img src="~/assets/images/pages/index/1index_banner_long.jpg" />
    </div>
    <!--  新闻、公告部分-->
    <div class="mainContainer post">
      <div class="swiper" v-if="newsList.length">
        <swiper :navigation="false" :modules="modules" :autoplay='{delay: 1000 * 5, disableOnInteraction: false}' :loop="false"
          effect="fade" id="swiperNews" :pagination="{dynamicBullets: false, clickable:true, clickableClass: 'swiper-pagination-clickable',}">
            <swiper-slide v-for="(item, index) in getList()" :key="index">
              <nuxt-link :to="`/xwzx/xwxq/${item.id}?from=/&default=新闻资讯`">
                <img :src="fileHost + item.newsTitleCover.split(',')[0]" alt="cover" v-if="item.newsTitleCover">
                <div class="emptyCover" v-else>最新新闻</div>
                <div class="content">
                  <div class="title">{{ item.newsTitle }}</div>
                  <div class="info">
                    集团新闻
                    <span></span>
                    {{ dayjs(item.createTime).format('YYYY-MM-DD') }}
                  </div>
                </div>
              </nuxt-link>
          </swiper-slide>
        </swiper>
      </div>
      <div class="news">
        <div class="header">
          <div :class="['title', postActive === 0 && 'active']" @mouseenter="postActive = 0">新闻资讯</div>
          <div :class="['title', postActive === 1 && 'active']" @mouseenter="postActive = 1">通知公告</div>
        </div>
        <div class="contaier">
          <template v-if="postActive === 0">
            <nuxt-link :to="item.k === 2 ? `/dqjs/xq/${item.id}?from=/&default=党群建设` : `/xwzx/xwxq/${item.id}?from=/&default=新闻资讯`" class="row" v-for="item in newsList" :key="item.id">
              <div class="left">
                <img src="../../assets/images/pages/about/son.png" alt="son">
                <div class="txt">{{ item.newsTitle }}</div>
              </div>
              <div class="time">{{ dayjs(item.createTime).format('YYYY-MM-DD') }}</div>
            </nuxt-link>
          </template>
          <template v-if="postActive === 1">
            <nuxt-link :to="`/xxgk/xq/${item.id}?from=/xxgk/tzgg`" class="row" v-for="item in notification" :key="item.id">
              <div class="left">
                <img src="../../assets/images/pages/about/son.png" alt="son">
                <div class="txt">{{ item.contentTitle }}</div>
              </div>
              <div class="time">{{ dayjs(item.createTime).format('YYYY-MM-DD') }}</div>
            </nuxt-link>
          </template>
        </div>
          <nuxt-link to="/xwzx/lqxw" class="more" v-if="postActive === 0">
              更多>>
          </nuxt-link>
      </div>
    </div>
    <nuxt-link to="/dqjs/xxgcddesdjs" class="banner_long" style="margin-bottom: 20px">
      <img src="~/assets/images/pages/index/dj.jpg" alt="">
    </nuxt-link>
<!--    精品工程-->
    <div class="boutiqueEngineeringBox">
      <div class="mainContainer boutiqueEngineering">
        <div class="header">
          <div class="name">精品工程</div>
          <nuxt-link to="/ywtx/gcjs" class="more">更多></nuxt-link>
        </div>
        <div class="body">
          <div class="left">
            <nuxt-link :to="`/ywtx/xq/${item.id}?from=/ywtx/gcjs`" :class="['menu', index === boutiqueEngineeringActiveIndex && 'active']" v-for="(item, index) in boutiqueEngineering" :key="index"
                       @mouseenter="boutiqueEngineeringActiveIndex !== index ? boutiqueEngineeringActiveIndex = index : ''"
                       :style="{height: `calc(620px / ${boutiqueEngineering.length})`}">
              <div class="color"></div>
              <div class="name">
                <div class="zh" :title="item.name">{{ item.contentTitle }}</div>
              </div>
              <div class="arrow"></div>
            </nuxt-link>
          </div>
          <nuxt-link :to="`/ywtx/xq/${boutiqueEngineering[boutiqueEngineeringActiveIndex]?.id}?from=/ywtx/gcjs`" class="right" v-if="boutiqueEngineering[boutiqueEngineeringActiveIndex]">
            <img :src="fileHost + boutiqueEngineering[boutiqueEngineeringActiveIndex]?.contentTitleCover" alt="">
            <div class="content" v-show="boutiqueEngineering[boutiqueEngineeringActiveIndex]?.content">{{ boutiqueEngineering[boutiqueEngineeringActiveIndex]?.content }}</div>
          </nuxt-link>
        </div>
      </div>
    </div>
    <!--  子级导航部分-->
    <div class="childNav">
      <div class="mainContainer content">
        <div class="nav">
          <nuxt-link to="/dqjs/xxgcddesdjs" class="navItem">
            <img src="../../assets/images/pages/index/dqjs.jpg" alt="">
            <div class="title">党群建设</div>
            <div class="content">增强“四个意识”，坚定“四个自信”，做到“两个维护”，聚焦“一创五强”战略目标，紧扣“五个加强”要求，以高质量党建引领高质量发展。</div>
          </nuxt-link>
          <nuxt-link to="/qywh" class="navItem">
            <img src="../../assets/images/pages/index/qywh.jpg" alt="">
            <div class="title">企业文化</div>
            <div class="content">秉承“开放、创新、合作、分享”发展理念，“专业、规范、透明、高效”工作理念，“利他共生、共创共享”的经营理念，“讲政治、顾大局、敢担当、走前头、守底线”的工作作风。</div>
          </nuxt-link>
          <nuxt-link to="/ywtx/gcjs" class="navItem">
            <img src="../../assets/images/pages/index/ywtx.jpg" alt="">
            <div class="title">业务体系</div>
            <div class="content">集团优化多元布局，协同产融发展,打造好“四个板块”,即“传统业务板块,科教文化板块,资本运作板块,城市发展板块”，营造好新经济新业态新模式的创新创业价值链平台。</div>
          </nuxt-link>
          <nuxt-link to="/rlzy/rczl" class="navItem">
            <img src="../../assets/images/pages/index/rlzy.jpg" alt="">
            <div class="title">人力资源</div>
            <div class="content">集团把职业教育培训作为人才开发培养的重要途径。培训工作坚持服务企业发展，以员工能力建设为核心，以员工岗位技能培训和知识更新培训为重点.</div>
          </nuxt-link>
        </div>
        <!-- <div class="slogan">发展理念：开放 · 创新 · 合作 · 分享</div> -->
      </div>
    </div>
<!--    漂浮移动-->
    <div id="floatAndMove" ref="floatAndMoveRef" @mouseenter="handleMouseOver"  @mouseleave="handleMouseOut" v-if="showFloatAndMove">
      <nuxt-link to="/qywh">
        <img src="../../assets/images/pages/index/floatAndMove.jpg" alt="">
      </nuxt-link>
      <div class="close" @click="closeFloatAndMove()"  v-if="showFloatAndMoveClose" title="关闭">
        <img src="../../assets/images/pages/index/close.png" alt="/">
      </div>
    </div>
  </div>
</template>

<script setup>
import {getAnnouncements, getNewsList} from "../../apis";
import dayjs from "dayjs";
import Header_nav from "../../layouts/components/headers/header_nav.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import {Autoplay, Navigation, EffectFade, Pagination} from "swiper";
import "swiper/css";
import 'swiper/css/effect-fade';
import 'swiper/css/bundle'
import {findGWEngineeringProjectDetails} from "../../apis/ywtx";

const modules = ref([Navigation, Autoplay, EffectFade, Pagination])
const fileHost = import.meta.env.VITE_FILE_HOST


const postActive = ref(0)
const showFloatAndMove = ref(true)
const showFloatAndMoveClose = ref(false)
const floatAndMoveRef = ref(null)
const floatAndMoveTimer= ref(null)
const floatAndMoveData = ref({
  x: 0,
  y: 0,
  xv: 1,
  yv: 1,
})

const boutiqueEngineeringActiveIndex = ref(0)
const boutiqueEngineering = ref([])

// findGWEngineeringProjectDetails
const boutiqueEngineeringRes = await useAsyncData('getboutiqueEngineeringRes', () => findGWEngineeringProjectDetails({page:1, pageSize: 10, contentTypeId: 1}))
if (boutiqueEngineeringRes.data.value.code !== 200) {
  boutiqueEngineering.value = []
  throw createError({statusCode: boutiqueEngineeringRes.data.value.code, statusMessage: encodeURIComponent("首页获取精品工程失败")})
} else {
  boutiqueEngineering.value = boutiqueEngineeringRes.data.value.data.list
}


function getList(){
  let arr = []
  for (let i = 0; i < newsList.value.length; i++) {
    let item = newsList.value[i]
    if (item.newsTitleCover) {
      arr.push(item)
    }
    if (arr.length >= 5) {
      break
    }
  }
  // 这次获取的都没有图片
  if (arr.length === 0) {
    // 取newsList前五个
    arr = newsList.value.slice(0, 5)
  }
  return arr
}

// 关闭浮窗
function closeFloatAndMove() {
  showFloatAndMove.value = false
  floatAndMoveTimer.value && clearInterval(floatAndMoveTimer.value)
}
// 浮窗开始
function toAnimate() {
  const ad = floatAndMoveRef.value
  //范围
  //左右
  if (floatAndMoveData.value.x < 0 || floatAndMoveData.value.x > window.innerWidth - ad.offsetWidth) {
    floatAndMoveData.value.xv = -floatAndMoveData.value.xv;
  }
  //上下
  if (floatAndMoveData.value.y < 0 || floatAndMoveData.value.y > window.innerHeight - ad.offsetHeight) {
    floatAndMoveData.value.yv = -floatAndMoveData.value.yv;
  }
  floatAndMoveData.value.x += floatAndMoveData.value.xv;
  floatAndMoveData.value.y += floatAndMoveData.value.yv;
  //获取到的x值赋值给ad的left
  ad.style.left = floatAndMoveData.value.x + "px";
  //获取到的y值赋值给ad的top
  ad.style.top = floatAndMoveData.value.y + "px";
}
// 鼠标放到浮窗上
function handleMouseOver() {
  clearInterval(floatAndMoveTimer.value)
  showFloatAndMoveClose.value = true
}
// 鼠标离开浮窗
function handleMouseOut() {
  floatAndMoveTimer.value = setInterval(toAnimate,40);
  showFloatAndMoveClose.value = false
}

onMounted(() => {
  handleMouseOut()
})

onBeforeUnmount(() => {
  floatAndMoveTimer.value && clearInterval(floatAndMoveTimer.value)
})



/**
 * @description: 获取新闻列表
 * */
const newsList = ref([]);
const newsListRes = await useAsyncData('getHomeNewsList', () => getNewsList({pageSize: 9}))
if (newsListRes.data.value.code !== 200) {
  newsList.value = []
  throw createError({statusCode: newsListRes.data.value.code, statusMessage: encodeURIComponent("获取新闻列表失败")})
} else {
  newsList.value = newsListRes.data.value.data.list
}

/**
 * @description: 获取通知公告
 * */
const notification = ref([]);
const notificationRes = await useAsyncData('getNotification', () => getAnnouncements(1))
if (notificationRes.data.value.code !== 200) {
  notification.value = []
  throw createError({statusCode: notificationRes.data.value.code, statusMessage: encodeURIComponent("获取通知公告失败")})
} else {
  notification.value = notificationRes.data.value.data.list
}




</script>

<style scoped lang="scss">
@import "index";
.boutiqueEngineeringBox {
  border-top: 1px solid #E4E7ED;
}
.boutiqueEngineering {
  margin-bottom: 20px;
  padding-top: 20px;
  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      color: #154D98F2;
      font-size: 26px;
      font-weight: bolder;
    }
    .more {
      color: #999;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 620px;
    .left {
      background-color: #F2F2F2FF;
      width: 22%;
      height: 100%;
      min-width: 280px;
      margin-right: 20px;
      flex-shrink: 0;
      .menu {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        justify-content: center;
        cursor: pointer;
        .name {
          .zh {
            font-size: 16px;
            color: #333;
            line-height: 1.2;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            margin-bottom: 10px;
          }
          .en {
            font-size: 14px;
            color: #999;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            line-height: 1;
          }
        }
      }
      .active {
        background-color: #154D98F2;
        border-left: 5px solid #f5a34d;
        transition: border-left .3s;
        .name {
          .zh {
            color: #fff;
          }
          .en {
            color: #eee;
          }
        }
      }
    }
    .right {
      flex: 1;
      height: 100%;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      transition: all .3s;
      > img {
        width: 100%;
        height: 100%;
      }
      .content {
        position: absolute;
        right: 0;
        bottom: 50px;
        padding: 20px 60px 20px 20px;
        background-color: rgba(66, 112, 181, .8);
        color: #FFFFFF;
        line-height: 1.78;
        width: 60%;
        font-size: 12px;
      }
    }
  }
}
</style>

